import { selectIsAuthenticated } from "../Redux/AuthRelated/AuthSlice.js";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import FullLayout from "../layouts/FullLayout.js";
import ExpiredPage from "../views/Admin/Expired.js";
import AdminDashboard from "../views/Admin/AdminDashboard.js";
import AdminLogin from "../views/Login/Login.js";
import AdminRegister from "../views/Register/Register.js";
import Branches from "../views/Admin/Branch/Branches.js";
import EditBranch from "../views/Admin/Branch/EditBranch.js";
import CreateBranch from "../views/Admin/Branch/CreateBranch.js";
import Classes from "../views/Admin/Class/Classes.js";
import CreateClass from "../views/Admin/Class/CreateClass.js";
import EditClass from "../views/Admin/Class/EditClass.js";
// import PromoteStudents from "../views/Admin/Student/PromoteStudents.js";

import CreateStudent from "../views/Admin/Student/CreateStudent.js";
import Students from "../views/Admin/Student/Students.js";
import EditStudent from "../views/Admin/Student/EditStudent.js";
import SingleStudent from "../views/Admin/Student/SingleStudent.js";
import Certificate from "../views/Admin/Student/certificate.js";
import Document from "../views/Admin/Student/Document.js";

import StudentCertificate from "../views/Admin/Student/studentCertificate.js";
import StudentDocument from "../views/Admin/Student/studentDocument.js";

// import ImportStudent from '../views/Admin/Student/ImportStudent.js';

import Organization from "../views/Admin/Organization/Organization.js";
import EditOrganization from "../views/Admin/Organization/EditOrganization.js";

import Fees from "../views/Admin/Finance/Fees/Fees.js";
import FeesType from "../views/Admin/Finance/FeesType/FeesType.js";
import EditFeesType from "../views/Admin/Finance/FeesType/EditFeesType.js";
import CreateFeesType from "../views/Admin/Finance/FeesType/CreateFeesType.js";
import FeesReminder from "../views/Admin/Finance/Fees/feesReminder.js";
import FeesReminderm from "../views/Admin/Finance/Fees/todayschedule.js";


import CreateTransaction from "../views/Admin/Finance/Transaction/CreateTransaction.js";
import UpdateTransaction from "../views/Admin/Finance/Transaction/UpdateTransaction.js";
import HistoryTransaction from "../views/Admin/Finance/Transaction/HistoryTransaction.js";
import DetailsTransaction from "../views/Admin/Finance/Transaction/DetailsTransaction.js";
import Receipt from "../views/Admin/Finance/Transaction/Receipt.js";

import CreateAcademicYear from "../views/Admin/Academics/CreateAcademicYear.js";

import Subjects from "../views/Admin/Examinations/subject/Subject.js";
import CreateSubject from "../views/Admin/Examinations/subject/CreateSubject.js";
import EditSubject from "../views/Admin/Examinations/subject/EditSubject.js";

import ExamTypes from "../views/Admin/Examinations/examType/ExamTypes.js";
import CreateExamType from "../views/Admin/Examinations/examType/CreateExamType.js";
import EditExamType from "../views/Admin/Examinations/examType/EditExamType.js";


import Exams from "../views/Admin/Examinations/Exam/Exams.js";
import CreateExam from "../views/Admin/Examinations/Exam/CreateExam.js";
import EditExam from "../views/Admin/Examinations/Exam/EditExam.js";

import CreateExamSubject from "../views/Admin/Examinations/Subject Setup/CreateExamSubject.js";
import ExamSubjectDetails from "../views/Admin/Examinations/Subject Setup/ExamSubjectDetails.js";
import EditExamSubject from "../views/Admin/Examinations/Subject Setup/EditExamSubject.js";


import MarkSetup from "../views/Admin/Examinations/Mark Setup/MarkSetup.js";
import VerifyEmail from "../views/Register/VerifyEmail.js";
import ForgotPassword from "../views/Admin/forgot/ForgotPassword.js";
import PasswordReset from "../views/Admin/forgot/PasswordReset.js";
import CreateFees from "../views/Admin/Finance/Fees/CreateFees.js";
import GenerateInvoice from "../views/Admin/Finance/Invoice/generateInvoice.js";
import FeesReport from "../views/Admin/Finance/Fees Report/FeesReport.js";
import StudentsReport from "../views/Admin/Finance/Fees Report/StudentsReport.js";
import OutstandingReport from "../views/Admin/Finance/Fees Report/OutstandingReport.js";
import { element } from "prop-types";


import Examsoverview from "../views/Admin/Examinations/Examoverview/Examsoverview.js";
import ExamStudents from "../views/Admin/Examinations/Examoverview/examstudents.js";
import Setting from "../views/Admin/Examinations/Examoverview/Setting.js";
import ReportStudent from "../views/Admin/Finance/Fees Report/ReportStudent.js";

const ProtectedAdminDashboard = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }
  return <FullLayout />;
};

const ThemeRoutes = [
  {
    path: "/auth/login",
    element: <AdminLogin />,
  },
  {
    path: "/auth/register",
    element: <AdminRegister />,
  },
  {
    path: "/verifyemail",
    element: <VerifyEmail />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <PasswordReset />,
  },
  { path: "/planexpired", element: <ExpiredPage /> },
  {
    path: "/",
    element: <ProtectedAdminDashboard />,
    children: [
      { path: "/", element: <Navigate to="/admindashboard" /> },

      { path: "/admindashboard", exact: true, element: <AdminDashboard /> },
      { path: "/organization", exact: true, element: <Organization /> },

      {
        path: "/edit/organization/:id",
        exact: true,
        element: <EditOrganization />,
      },

      {
        path: "/create/academicyear",
        exact: true,
        element: <CreateAcademicYear />,
      },

      { path: "/branches", exact: true, element: <Branches /> },
      { path: "/createbranch", exact: true, element: <CreateBranch /> },
      { path: "/updatebranch/:id", exact: true, element: <EditBranch /> },

      { path: "/createclass", exact: true, element: <CreateClass /> },
      { path: "/classes", exact: true, element: <Classes /> },
      { path: "/updateclass/:id", exact: true, element: <EditClass /> },

      // { path: "/students/promote", exact: true, element: <PromoteStudents /> },

      { path: "/createstudent", exact: true, element: <CreateStudent /> },
      { path: "/students", exact: true, element: <Students /> },
      { path: "/updatestudent/:id", exact: true, element: <EditStudent /> },
      { path: "/singlestudent/:id", exact: true, element: <SingleStudent /> },
      { path: "/students/certificate", exact: true, element: <Certificate /> },
      { path: "/students/document", exact: true, element: <Document /> },
      {
        path: "/student/certificate/:id",
        exact: true,
        element: <StudentCertificate />,
      },
      {
        path: "/student/document/:id",
        exact: true,
        element: <StudentDocument />,
      },

      // { path: "/importstudent", exact: true, element: <ImportStudent /> },

      { path: "/receivable", exact: true, element: <Fees /> },
      { path: "/receivable/create/:id", exact: true, element: <CreateFees /> },
      { path: "/feestypes", exact: true, element: <FeesType /> },
      { path: "/create/feestype", exact: true, element: <CreateFeesType /> },
      { path: "/edit/feestype/:id", exact: true, element: <EditFeesType /> },
      { path: "/feesreport", exact: true, element: <FeesReport /> },
      { path: "/feesreminder", exact: true, element: <FeesReminder /> },
      { path: "/feesreminderschedule", exact: true, element: <FeesReminderm /> },
      { path: "/studentfeesreport", exact: true, element: <StudentsReport /> },
      {
        path: "/outstandingreport",
        exact: true,
        element: <OutstandingReport />,
      },
      {
        path: "/studentsreport",
        exact: true,
        element: <ReportStudent />,
      },

      {
        path: "/receivable/create/transaction/:id",
        exact: true,
        element: <CreateTransaction />,
      },
      {
        path: "/update/transaction/:id",
        exact: true,
        element: <UpdateTransaction />,
      },
      {
        path: "/history/transaction/:id",
        exact: true,
        element: <HistoryTransaction />,
      },
      {
        path: "/details/transaction/:id",
        exact: true,
        element: <DetailsTransaction />,
      },
      {
        path: "/details/transaction/receipt",
        exact: true,
        element: <Receipt />,
      },
      {
        path: "/receivable/create/transaction/invoice/:id",
        exact: true,
        element: <GenerateInvoice />,
      },
      { path: "/create/exam", exact: true, element: <CreateExam /> },
      { path: "/exams", exact: true, element: <Exams /> },
      { path: "/edit/exam/:id", exact: true, element: <EditExam /> },

      { path: "/create/subject", exact: true, element: <CreateSubject /> },
      { path: "/subjects", exact: true, element: <Subjects /> },
      { path: "/edit/subject/:id", exact: true, element: <EditSubject /> },

      { path: "/create/examtype", exact: true, element: <CreateExamType /> },
      { path: "/examtypes", exact: true, element: <ExamTypes /> },
      { path: "/edit/examtype/:id", exact: true, element: <EditExamType /> },

      { path: "/exam/subject", exact: true, element: <ExamSubjectDetails /> },
      {
        path: "/create/examsubject",
        exact: true,
        element: <CreateExamSubject />,
      },
      {
        path: "/edit/examsubject/:id",
        exact: true,
        element: <EditExamSubject />,
      },
      { path: "/setup/mark", exact: true, element: <MarkSetup /> },

      { path: "/examsoverview", exact: true, element: <Examsoverview /> },
      { path: "/examsstudents/:id", exact: true, element: <ExamStudents /> },
      { path: "/examsoverview/setting/:id", exact: true, element: <Setting /> },

      { path: "/*", element: <Navigate to="/" /> },
    ],
  },
];

export default ThemeRoutes;
