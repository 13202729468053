import React, { useState } from "react";
import { Button, Nav, NavItem, Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

const navigation = [
  {
    title: "Dashboard",
    href: "/admindashboard",
    icon: "bi bi-speedometer2",
  },
  {
  title: "Settings",
  icon: "bi bi-gear",
  subItems: [
    {
      title: "Organization",
      href: "/organization",
      icon: "bi bi-building",
    },
    {
      title: "Branches",
      href: "/branches",
      icon: "bi bi-tree",
    },
    {
      title: "Classes",
      href: "/classes",
      icon: "bi bi-book",
    },
      {
    title: "Academics",
    href: "/create/academicyear",
    icon: "bi bi-calendar",
  },
  ]
  },
  {
    title: "Admissions",
    icon: "bi bi-person-plus",
    subItems: [
      {
        title: "Students",
        href: "/students",
        icon: "bi bi-person",
      },
      // {
      //   title: "Promote Students",
      //   href: "/students/promote",
      //   icon: "bi bi-person",
      // },

      {
        title: "Certificates",
        href: "/students/certificate",
        icon: "bi bi-file-earmark-text",
      },
    ]
  },
  {
    title: "Finance",
    icon: "bi bi-wallet",
    subItems: [
      { title: "Fees Types", href: "/feestypes", icon: "bi bi-wallet2", },
      { title: "Receivable", href: "/receivable", icon: "bi bi-currency-rupee", },
      { title: "Fees Reminder", href: "/feesreminder", icon: "bi bi-bell", },
      { title: "SMS Schedule", href: "/feesreminderschedule", icon: "bi bi-clock", },
    ]
  },
  {
    title: "Reports",
    icon: "bi bi-bar-chart",
    subItems: [
      { title: "Student Report", href: "/studentsreport", icon: "bi bi-person-lines-fill", },
      { title: "Student Fees Status Report", href: "/studentfeesreport", icon: "bi bi-clipboard-check", },  
      { title: "Fees Report", href: "/feesreport", icon: "bi bi-graph-up", },
      { title: "Outstanding Payment Report", href: "/outstandingreport", icon: "bi bi-calendar-x", },
    ]
  },
  {
    title: "Examinations",
    icon: "bi bi-file-earmark-medical",
    subItems: [
      { title: "Subjects", href: "/subjects", icon: "bi bi-book" },
      { title: "Exam Types", href: "/examtypes", icon: "bi bi-pencil-square" },
      { title: "Exam Setup", href: "/exams", icon: "bi bi-card-list" },
      { title: "Exam Subject Setup", href: "/exam/subject", icon: "bi bi-journal" },
      { title: "Assign Marks", href: "/setup/mark", icon: "bi bi-pencil" },
      { title: "Exam Overview", href: "/examsoverview", icon: "bi bi-table" },
    ],
  },
  {
    title: "Document Management",
    href: "/students/document",
    icon: "bi bi-person",
  },
];

const Sidebar = () => {
  const isMobile = window.innerWidth <= 769;
  const [isFinanceOpen, setFinanceOpen] = useState(false);
  const [isCertificateOpen, setCertificateOpen] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isAdmissionsOpen, setAdmissionsOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isExaminationsOpen, setExaminationsOpen] = useState(false);

  const location = useLocation();

  const toggleSettings = () => setSettingsOpen(!isSettingsOpen);
  const toggleFinance = () => setFinanceOpen(!isFinanceOpen);
  const toggleCertificate = () => setCertificateOpen(!isCertificateOpen);
  const toggleAdmissions = () => setAdmissionsOpen(!isAdmissionsOpen);
  const toggleReportOpen = () => setIsReportOpen(!isReportOpen);
  const toggleExaminations = () => setExaminationsOpen(!isExaminationsOpen);

  const handleNavItemClick = () => {
    document.getElementById("sidebarArea").classList.remove("showSidebar");
  };

  return (
    <div className="px-3 pt-3 overflow-auto h-100" style={{paddingBottom: isMobile ?  "75px":"0.5rem"  }}>
      <div>
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              {navi.subItems ? (
                <>
                  {navi.title === "Finance" ? (
                    <>
                      <Button
                        color="link"
                        className={`text-decoration-none px-0 d-flex align-items-center ${navi.subItems.some(subItem => location.pathname.startsWith(subItem.href)) ? 'text-primary' : 'text-secondary'} py-3`}
                        onClick={toggleFinance}
                        style={{ width: '100%', textAlign: 'left' }}
                      >
                        <i className={navi.icon}></i>
                        <span className="ms-3 d-inline-block">{navi.title}</span>
                        <i className={`bi ${isFinanceOpen ? 'bi-chevron-up' : 'bi-chevron-down'} ms-auto`}></i>
                      </Button>
                      <Collapse isOpen={isFinanceOpen}>
                        <Nav vertical className="ps-3">
                          {navi.subItems.map((subItem, subIndex) => (
                            <NavItem key={subIndex}>
                              <Link
                                to={subItem.href}
                                className={
                                  location.pathname === subItem.href
                                    ? "text-primary nav-link py-2"
                                    : "nav-link text-secondary py-2"
                                }
                                onClick={handleNavItemClick} 
                              >
                                <i className={subItem.icon}></i> 
                                <span className="ms-2 d-inline-block">{subItem.title}</span>
                              </Link>
                            </NavItem>
                          ))}
                        </Nav>
                      </Collapse>
                    </>
                  )  : navi.title === "Settings" ? (
                    <>
                      <Button
                         color="link"
                         className={`text-decoration-none px-0 d-flex align-items-center ${navi.subItems.some(subItem => location.pathname.startsWith(subItem.href)) ? 'text-primary' : 'text-secondary'} py-3`}
                         onClick={toggleSettings}
                         style={{ width: '100%', textAlign: 'left' }}
                       >
                         <i className={navi.icon}></i>
                         <span className="ms-3 d-inline-block">{navi.title}</span>
                         <i className={`bi ${isSettingsOpen ? 'bi-chevron-up' : 'bi-chevron-down'} ms-auto`}></i>
                       </Button>
                       <Collapse isOpen={isSettingsOpen}>
                         <Nav vertical className="ps-3">
                           {navi.subItems.map((subItem, subIndex) => (
                             <NavItem key={subIndex}>
                               <Link
                                 to={subItem.href}
                                 className={
                                   location.pathname === subItem.href
                                     ? "text-primary nav-link py-2"
                                     : "nav-link text-secondary py-2"
                                 }
                                 onClick={handleNavItemClick}
                               >
                                 <i className={subItem.icon}></i> 
                                 <span className="ms-2 d-inline-block">{subItem.title}</span>
                               </Link>
                             </NavItem>
                           ))}
                         </Nav>
                       </Collapse>
                    </>
                  )  : navi.title === "Admissions" ? (
                    <>
                      <Button
                        color="link"
                        className={`text-decoration-none px-0 d-flex align-items-center ${navi.subItems.some(subItem => location.pathname === subItem.href) ? 'text-primary' : 'text-secondary'} py-3`}
                        onClick={toggleAdmissions}
                        style={{ width: '100%', textAlign: 'left' }}
                      >
                        <i className={navi.icon}></i>
                        <span className="ms-3 d-inline-block">{navi.title}</span>
                        <i className={`bi ${isAdmissionsOpen ? 'bi-chevron-up' : 'bi-chevron-down'} ms-auto`}></i>
                      </Button>
                      <Collapse isOpen={isAdmissionsOpen}>
                        <Nav vertical className="ps-3">
                          {navi.subItems.map((subItem, subIndex) => (
                            <NavItem key={subIndex}>
                              <Link
                                to={subItem.href}
                                className={
                                  location.pathname === subItem.href
                                    ? "text-primary nav-link py-2"
                                    : "nav-link text-secondary py-2"
                                }
                                onClick={handleNavItemClick}
                              >
                                <i className={subItem.icon}></i> 
                                <span className="ms-2 d-inline-block">{subItem.title}</span>
                              </Link>
                            </NavItem>
                          ))}
                        </Nav>
                      </Collapse>
                      
                    </>
                     ) : navi.title === "Examinations" ? (
                      <>
                        <Button
                          color="link"
                          className={`text-decoration-none px-0 d-flex align-items-center ${navi.subItems.some(
                            (subItem) => location.pathname === subItem.href
                          ) ? "text-primary" : "text-secondary"} py-3`}
                          onClick={toggleExaminations}
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          <i className={navi.icon}></i>
                          <span className="ms-3 d-inline-block">{navi.title}</span>
                          <i className={`bi ${isExaminationsOpen ? "bi-chevron-up" : "bi-chevron-down"} ms-auto`}></i>
                        </Button>
                        <Collapse isOpen={isExaminationsOpen}>
                          <Nav vertical className="ps-3">
                            {navi.subItems.map((subItem, subIndex) => (
                              <NavItem key={subIndex}>
                                <Link
                                  to={subItem.href}
                                  className={
                                    location.pathname === subItem.href
                                      ? "text-primary nav-link py-2"
                                      : "nav-link text-secondary py-2"
                                  }
                                  onClick={handleNavItemClick}
                                >
                                  <i className={subItem.icon}></i>
                                  <span className="ms-2 d-inline-block">{subItem.title}</span>
                                </Link>
                              </NavItem>
                            ))}
                          </Nav>
                        </Collapse>
                      </>
                  ) : navi.title === "Reports" ? (
                    <>
                      <Button
                        color="link"
                        className={`text-decoration-none px-0 d-flex align-items-center ${navi.subItems.some(subItem => location.pathname === subItem.href) ? 'text-primary' : 'text-secondary'} py-3`}
                        onClick={toggleReportOpen}
                        style={{ width: '100%', textAlign: 'left' }}
                      >
                        <i className={navi.icon}></i>
                        <span className="ms-3 d-inline-block">{navi.title}</span>
                        <i className={`bi ${isReportOpen ? 'bi-chevron-up' : 'bi-chevron-down'} ms-auto`}></i>
                      </Button>
                      <Collapse isOpen={isReportOpen}>
                        <Nav vertical className="ps-3">
                          {navi.subItems.map((subItem, subIndex) => (
                            <NavItem key={subIndex}>
                              <Link
                                to={subItem.href}
                                className={
                                  location.pathname === subItem.href
                                    ? "text-primary nav-link py-2"
                                    : "nav-link text-secondary py-2"
                                }
                                onClick={handleNavItemClick}
                              >
                                <i className={subItem.icon}></i> 
                                <span className="ms-2 d-inline-block">{subItem.title}</span>
                              </Link>
                            </NavItem>
                          ))}
                        </Nav>
                      </Collapse>
                      
                    </>
                  ) : (
                    <Link
                      to={navi.href}
                      className={
                        location.pathname === navi.href
                          ? "text-primary nav-link py-3"
                          : "nav-link text-secondary py-3"
                      }
                      onClick={handleNavItemClick} 
                    >
                      <i className={navi.icon}></i>
                      <span className="ms-3 d-inline-block">{navi.title}</span>
                    </Link>
                  )}
                </>
              ) : (
                <Link
                  to={navi.href}
                  className={
                    location.pathname === navi.href
                      ? "text-primary nav-link py-3"
                      : "nav-link text-secondary py-3"
                  }
                  onClick={handleNavItemClick} 
                >
                  <i className={navi.icon}></i>
                  <span className="ms-3 d-inline-block">{navi.title}</span>
                </Link>
              )}
            </NavItem>
          ))}
          <Button
            style={{ backgroundColor: "#fb0f01", border: "none" }}
            className="btn-no-radius mt-2"
          >
            <Link className="text-white text-decoration-none" to="/" onClick={handleNavItemClick}>
              Go to Home
            </Link>
          </Button>
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
